import React from "react";
import "../App.css";

function Contact() {
  return (
    <>
      <div classname="container-fluid" id="contact">
        <div classname="row justify-content-center">
          <div classname="col-xs-8 justify-content-center">
            <h2 id="contactLabel"><br />
              <center>Get in Touch:</center>
            </h2>
          </div>
        </div>
      </div>

      <div classname="container-fluid" id="contactList">
        <div classname="row justify-content-center">
          <div classname="col-xs-8 d-flex justify-content-center">
            <a href="mailto:marykatewhite@gmail.com">
              <p className="contactButton">
                Email.
              </p>
            </a>
          </div>
          <div classname="col-xs-8 d-flex justify-content-center">
            <a
              href="https://www.linkedin.com/in/marykatewhite/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="contactButton">
                LinkedIn.
              </p>
            </a>
          </div>
          <div classname="col-xs-12 col-sm-2 d-flex justify-content-center">
            <a
              href="https://github.com/marykatewhite"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="contactButton">
                Github.
              </p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
