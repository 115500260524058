import React, { Component } from "react";
import { Row } from "react-bootstrap";
import works from "../works.json";
import Work from "./work"
import "../App.css";

class WorkPage extends Component {
    constructor(props) {
      super(props);
      this.state = { works };
    }

    render() {
        return (
            
            <div className="container" id="workCards">
                <div className="row">
                    <div className="col-xs-8" id="workIntro">
                        <h2></h2>
                    </div>
                </div>
                <Row xs={1} md={3} className="g-4">
                    <Work works={this.props.children} />
                </Row>
            </div>
        );
    }
}

export default WorkPage;