import React, { Component } from "react";
import { Route, BrowserRouter as Router }  from 'react-router-dom';
// import About from "./components/about";
// import Header from "./components/header.js";
// import Contact from "./components/contact.js"
// import Resume from "./components/resume.js";
// import ControlledCarousel from "./components/carousel";
// import WorkPage from "./components/workpage";
// import Footer from "./components/footer";
import Home from "./components/home";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "./App.css";


class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">
        <Route exact path="/" component={ Home } />
        </div>
      </Router>
    );
  }
}

export default App;
