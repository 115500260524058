import React, { Component } from "react";
import {
  MDBCard,
  MDBRipple,
  MDBCardTitle,
  MDBCardText,
  MDBCardOverlay,
  MDBCardImage
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { Col } from "react-bootstrap";
import works from "../works.json";
import "../App.css";

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = { works };
  }

  render() {
    return this.state.works.map(work => {
      const {
        // id,
        category,
        name,
        altimg,
        img,
        description,
        externallink
      } = work;

      return (
        <Col>
            <a href={externallink}>
            <MDBCard background="dark" className="text-white">
              <MDBRipple
                className="bg-image hover-zoom"
                rippleTag="div"
                rippleColor="dark"
                style={{ maxWidth: "24rem" }}
              >
                <MDBCardImage overlay hover-zoom src={img} alt={altimg} />
              </MDBRipple>
              <MDBCardOverlay>
                <div className="hover-overlay">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(54, 45, 36, .8)" }}
                  >
                    <div className="cardContent">
                      <div className="categoryDiv"><MDBCardText>{category}</MDBCardText></div>
                      <MDBCardTitle>{name}</MDBCardTitle>
                      <MDBCardText>{description}</MDBCardText>
                      
                    </div>
                  </div>
                </div>
              </MDBCardOverlay>
            </MDBCard>
          </a>
        </Col>
      );
    });
  }
}

export default Work;
