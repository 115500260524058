import React from "react";
import "../App.css";

function About() {
    return (
        <div className="container" id="aboutMe">
            <div className="row">
            <div className="col-xs-8">
                <h2 >About:</h2>
            </div>
            </div>
        <div className="row">
            <div className="col-xs-8">
                <p id="bioText">I am Mary Kate White.<br /><br />I have worked as an editor, designer and web developer for newspapers, magazines, blogs and
                    small businesses in the United States and China since 2010.<br /><br />
                    
                    <br /><a href="./images/MaryKateWhiteresume.pdf" target="_blank"><b>Click here to view my resume.</b></a><br /><br />
                </p>
            </div>
        </div>
    </div>
    );
  }
  
  export default About;