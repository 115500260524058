import React from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement
} from "mdb-react-ui-kit";
import "../App.css";

function ControlledCarousel() {
  return (
    <MDBCarousel>
      <MDBCarouselInner>
        <MDBCarouselItem id="codeAndContentSlide" itemId={0}>
          <picture>
            <source
              media="(max-width: 425px)"
              srcset="./images/mobileIndSlide1.png"
            />
            <img
              src="./images/slide1.png"
              class="d-block w-100"
              alt="Code and Content"
            />
          </picture>
        </MDBCarouselItem>

        <MDBCarouselItem id="codeSlide" itemId={1}>
        <picture>
            <source
              media="(max-width: 425px)"
              srcset="./images/mobileIndSlide2.png"
            />
            <img
              src="./images/slide2.png"
              class="d-block w-100"
              alt="Code and Content"
            />
          </picture>
        </MDBCarouselItem>

        <MDBCarouselItem id="contentSlide" itemId={2}>
        <picture>
            <source
              media="(max-width: 425px)"
              srcset="./images/mobileIndSlideContent.png"
            />
            <img
              src="./images/slide3.png"
              class="d-block w-100"
              alt="Code and Content"
            />
          </picture>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
  );
}

export default ControlledCarousel;

//   <MDBCarousel>
//   <MDBCarouselInner>
//     <MDBCarouselItem id='codeAndContentSlide' itemId={0}>
//       <MDBCarouselElement id='mainDesk' src='./images/slide1.png' alt='Code and Content' />
//       <MDBCarouselElement id='mainMobile' src='./images/mobileIndSlide1.png' alt='Code and Content' />
//     </MDBCarouselItem>

//     <MDBCarouselItem id='codeSlide' itemId={1}>
//       <MDBCarouselElement id='codeDesk' src='./images/slide2.png' alt='Code' />
//       <MDBCarouselElement id='codeMobile' src='./images/mobileIndSlide2.png' alt='Code' />
//     </MDBCarouselItem>

//     <MDBCarouselItem id='contentSlide' itemId={2}>
//       <MDBCarouselElement id='contentDesk' src='./images/slide3.png' alt='Content' />
//       <MDBCarouselElement id='contentMobile' src='./images/mobileIndSlideContent.png' alt='Content' />
//     </MDBCarouselItem>
//   </MDBCarouselInner>
// </MDBCarousel>
