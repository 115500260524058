import React, { Component } from "react";
import "../App.css";
import designs from "../designs.json";
import writings from "../writings.json";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { designs, writings };
  }

  render() {
    return (
      <header>
        <nav className="nav justify-content-center">
          <a className="nav-link active" href="#aboutMe">
            About
          </a>
          <a className="nav-link" href="#workCards">
            Work
          </a>
          <a className="nav-link" href="./images/MaryKateWhiteresume.pdf" target="_blank">
            Resume
          </a>
          <a className="nav-link" href="#contact">
            Contact
          </a>
        </nav>
      </header>
    );
  }
}

export default Header;
