import React, { Component } from "react";
import About from "./about";
import Header from "./header.js";
import Contact from "./contact.js";
import ControlledCarousel from "./carousel";
import WorkPage from "./workpage";
import Footer from "./footer";
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import "../App.css";

class Home extends Component {

    render() {
      return (
          <div className="Home">
            <Header />
            <ControlledCarousel />
            <About />
            <WorkPage />
            <Contact />
            <Footer />
          </div>

      );
    }
  }
  
  export default Home;